/*
Template Name: Material Pro Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/
// Variables

$body-font: 'Poppins', sans-serif;
$heading-font: 'Poppins', sans-serif;

$primary: #ff0000;
$secondary: #6d6d6d;

$body-color: #eef5f9;
$heading-text: #455a64;
$body-text: #67757c;
$sidebar-text: #607d8b;
$sidebar-icons: #99abb4;


$theme-color: #1e88e5;

/*bootstrap Color*/
$danger: #fc4b6c;
$success: #26c6da;
$warning: #ffb22b;
$info: #1e88e5;
$inverse: #2f3d4a;
$muted: #99abb4;
$dark: #263238;
$light: #f2f4f8;
$extra-light: #ebf3f5;
/*Light colors*/
$light-danger: #f9e7eb;
$light-success: #e8fdf8;
$light-warning: #fff8ec;
$light-primary: #f1effd;
$light-info: #e3f3fd;
$light-inverse: #f6f6f6;
$light-megna: #e0f2f4;

$danger-dark: #e6294b;
$success-dark: #1eacbe;
$warning-dark: #e9ab2e;
$primary-dark: #6352ce;

/*Normal Color*/
$white: #ffffff;
$purple: #7460ee;

/*Extra Variable*/
$rgt: right;
$lft: left;
$border: rgba(120, 130, 140, 0.13);
$radius: 4px;

//PALETTE
$mat-primary: (
  50: #ffe7e6,
  100: #ffc7b8,
  200: #ffa28a,
  300: #ff795b,
  400: #ff5436,
  500: #ff200c,
  600: #ff1507,
  700: #ff0000,
  800: #f10000,
  900: #da0000,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
  )
);
$mat-secondary: (
  50: #f9f9f9,
  100: #f2f2f2,
  200: #e9e9e9,
  300: #d9d9d9,
  400: #b5b5b5,
  500: #959595,
  600: #6d6d6d,
  700: #5a5a5a,
  800: #3b3b3b,
  900: #1b1b1b,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
  )
);
